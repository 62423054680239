import "./App.css";
import Split from "react-split";
import Console from "./components/Console";
import Editor from "./components/Editor";
import Header from "./components/Header";

function App() {
  return (
    <div className="App" style={{ height: "100vh", overflow: "hidden" }}>
      <Header />
      <Split className="split" sizes={[60, 40]} style={{ height: "100%" }}>
        <Editor />
        <Console/>
      </Split>
    </div>
  );
}

export default App;
