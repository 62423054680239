import React, { useState, useCallback, useEffect } from "react";
import Editor from "@monaco-editor/react";
import { FaPlay } from "react-icons/fa";

import LZString from "lz-string";

const TYPE_TIMEOUT = 1000;

const compressContent = (content) => {
  // const escaped = unescape(encodeURIComponent(content));
  return LZString.compressToEncodedURIComponent(content);
}

const decompressContent = (compressed) => {
  // const unescaped = decodeURIComponent(escape(compressed));
  return LZString.decompressFromEncodedURIComponent(compressed);
}

const EditorComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [timeoutRef, setTimeoutRef] = useState(null); 
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    if (!loaded) {
      const saved = sessionStorage.getItem("savedEditorContent");
      if (saved) {
        setEditorContent(decompressContent(saved));
      } else {
        const url = new URL(window.location.href);
        const c = url.searchParams.get("c");
        if (c) {
          setEditorContent(decompressContent(c));
        }
      }
      setLoaded(true);
    }
  }, [setEditorContent, loaded, setLoaded]);

  const runCode = useCallback(() => {
    try {
      console.log("Running...");
      eval(editor.getValue()); // eslint-disable-line no-eval
    } catch (e) {
      console.error(e);
    }
  }, [editor]);

  const handleTimeout = useCallback(() => {
    if (editor) {
      const compressed = compressContent(editor.getValue());
      sessionStorage.setItem("savedEditorContent", compressed)
    }
  }, [editor]);

  return (
    <div style={{ height: "100%" }}>
      <div style={{ 
        padding: 10,
        backgroundColor: "#333",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}>
        <div style={{ color: "#eee", fontSize: 12 }}>
          EDITOR
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer"
        }} onClick={runCode}>
          <span style={{
            color: "#eee",
            fontSize: 12,
            marginRight: 5
          }}>
            Run
          </span>
          <FaPlay color="#eee" size={12}/>
        </div>
      </div>
      <Editor
        automaticLayout={true}
        value={editorContent}
        onMount={(editor) => {
          setEditor(editor)
        }}
        theme="vs-dark"
        options={{
          minimap: {
            enabled: false
          }
        }}
        defaultLanguage="javascript"
        defaultValue=""
        onChange={() => {
          if (timeoutRef) {
            clearInterval(timeoutRef);
          }

          setTimeoutRef(setTimeout(handleTimeout, TYPE_TIMEOUT));
        }}
      />
    </div>
  )
} 

export default EditorComponent;