import React, { useState } from "react";
import { FaHeart, FaShareSquare } from "react-icons/fa";

const Header = () => {
  const [copied, setCopied] = useState(false);

  const share = () => {
    const saved = sessionStorage.getItem("savedEditorContent");
    navigator.clipboard.writeText(`${window.location.origin}?c=${saved}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }

  return (
    <div style={{ 
      backgroundColor: "#2d74bc",
      height: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 10,
      paddingRight: 10
    }}>
      <div>
        <span style={{ color: "#fff", fontWeight: "bold" }}>jsdev.</span>
        <FaHeart size={12} color="#e02020" />
      </div>
      {copied
        ? <span style={{ color: "#fff", fontWeight: "bold", fontSize: 12 }}>Link Copied to Clipboard!</span>
        : <div style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer"
          }} onClick={share}>
            <span style={{ color: "#fff", fontWeight: "bold", marginRight: 6, fontSize: 12 }}>Share</span>
            <FaShareSquare sizse={16} color="#fff" />
          </div>
      }
      
    </div>
  )
} 

export default Header;