import React, {useEffect, useState, useCallback} from "react";
import { Hook, Console, Decode } from 'console-feed';
import { FaTimes } from "react-icons/fa";


const ConsoleComponent = () => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    Hook(window.console, (log) => setLogs(logs => [...logs, Decode(log)]))
  }, []);

  const clearConsole = useCallback(() => {
    setLogs([]);
  }, [setLogs])

  return (
    <div style={{ backgroundColor: "#1e1e1e", overflowY: "scroll" }}>
      <div style={{ 
          padding: 10,
          backgroundColor: "#333",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <div style={{ color: "#eee", fontSize: 12 }}>
            CONSOLE
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer"
          }} onClick={clearConsole}>
            <span style={{
              color: "#eee",
              fontSize: 12,
              marginRight: 5
            }}>
              Clear
            </span>
            <FaTimes color="#eee" size={14}/>
          </div>
        </div>
      <Console
        logs={logs} 
        variant="dark" 
      />
    </div>
  )
} 

export default ConsoleComponent;